html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto;
  font-size: 16px;
  color: #565a5c;
}

.search-filters {
  display: flex;
}

.aisdemo--left-column {
  height: 400px;
  flex: 2 1 0;
}

.aisdemo--right-column {
  position: relative;
  height: 400px;
  flex: 1 1 0;
}

/* HEADER
// ------------------------- */
.aisdemo-navbar {
  margin: 0;
  border-bottom: 1px solid #dce0e0;
  position: relative;
  z-index: 99;
}

.aisdemo-navbar .is-logo {
  display: inline-block;
  position: relative;
  top: -8px;
  left: 16px;
}

.aisdemo-navbar .logo {
  display: inline-block;
  padding: 14px 22px;
  font-size: 40px;
  line-height: 1;
  font-family: Courier;
  color: #FF0000;
  border-right: 1px solid #dce0e0;
}

.aisdemo-navbar .fa-search {
  font-size: 30px;
  margin-left: 10px;
  color: #dce0e0;
}

.ais-SearchBox {
  position: absolute;
  width: 280px;
  height: 33px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
  left: 205px;
  top: 20px;
}

.ais-SearchBox-body,
.ais-SearchBox-form {
  width: 100%;
  height: 100%;
}

.ais-SearchBox-input {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 1px;
  box-shadow: inset 0 0 0 0px #cccccc;
  background: #ffffff;
  padding: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.ais-SearchBox-input:hover {
  box-shadow: inset 0 0 0 0px #b3b3b3;
}

.ais-SearchBox-input:focus,
.SearchBox__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 0px #d6dee3;
  background: #ffffff;
}

.ais-SearchBox-input::-webkit-input-placeholder {
  color: #9aaeb5;
}

.ais-SearchBox-input::-moz-placeholder {
  color: #9aaeb5;
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: #9aaeb5;
}

.ais-SearchBox-input::placeholder {
  color: #9aaeb5;
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}

/* FILTERS
// ------------------------- */
.aisdemo-filters .aisdemo-filter {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dce0e0;
}

.aisdemo-filters .aisdemo-filter-title {
  font-size: 1em;
  font-weight: normal;
  padding-top: 9px;
  padding-left: 30px;
}

.aisdemo-filters .date,
.aisdemo-filters #guests select {
  font-size: 0.8em;
  padding: 8px 10px;
  border: 1px solid #dce0e0;
}

.aisdemo-filters #guests select {
  width: 100%;
  padding-right: 20px;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.aisdemo-filters #guests select:focus {
  outline: none;
}

.aisdemo-filters .guests-caret:before {
  line-height: 1;
  position: absolute;
  top: 0;
  right: 20px;
  width: 2em;
  padding-top: 0.8em;
  content: '\25bc';
  text-align: center;
  pointer-events: none;
  color: #82888a;
}

.ais-refinement-list--count {
  display: none;
}

.ais-refinement-list--item {
  display: inline-block;
  cursor: pointer;
}

.ais-refinement-list--item label {
  font-size: 0.8em;
  font-weight: normal;
  box-sizing: border-box;
  width: 100%;
  padding: 9px 10px 7px;
  background: #edefed;
  border-radius: 4px;
}

.ais-refinement-list--checkbox {
  position: relative;
  bottom: 3px;
  float: right;
  width: 1.25em;
  height: 1.25em;
  margin-left: 20px;
  vertical-align: top;
  border: 1px solid #dce0e0;
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ais-refinement-list--checkbox:focus {
  outline: none;
}

.ais-refinement-list--item__active .ais-refinement-list--checkbox:before {
  font-size: 0.85em;
  position: absolute;
  width: 1.25em;
  content: '\2713';
  text-align: center;
  color: #FF0000;
}

.ais-GeoSearch-map {
  height: 400px;
}

.ClearGeoRefinement button {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background-color: #FF0000;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.85em;
}

.ClearGeoRefinement button:disabled {
  display: none;
}

/* STATS
// ------------------------- */
#stats {
  font-size: 1em;
  font-weight: normal;
  position: absolute;
  bottom: 0;
  padding: 5px 15px;
}

/* MAP
// ------------------------- */
#map {
  height: 400px;
}

.marker {
  transform: translate(-50%, -100%) scale(0.5, 0.5);
}

.capacity-filter {
  padding: 9px 10px 7px;
  background: #edefed;
}

.capacity-menu-wrapper {
  font-size: 0.8em;
  font-weight: normal;
  box-sizing: border-box;
  width: 100%;
  padding: 6px 0px 5px;
}

.capacity-menu-wrapper select {
  width: 100%;
  background: #edefed;
  height: 27px;
}

/* RESULTS
// ------------------------- */
#results {
  padding: 40px 20px;
  background: #edefed;
}

#hits .hit .pictures-wrapper {
  position: relative;
}

#hits .hit .pictures-wrapper .picture {
  width: 100%;
}

#hits .hit .pictures-wrapper .profile {
  position: absolute;
  bottom: -16px;
  right: 12px;
  width: 60px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
}

#hits .hit .infos {
  height: 90px;
  padding: 16px 20px;
}

#hits .hit .infos h4 {
  font-size: 1em;
  font-weight: normal;
}

#hits .hit .infos p {
  font-size: 0.8em;
  color: #949697;
}

#hits .hit .infos em {
  font-style: normal;
  color: #FF0000;
}

/* PAGINATION
// ------------------------- */
.ais-Pagination {
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.ais-Pagination .ais-Pagination-item {
  display: inline-block;
  border: 1px solid;
  border-radius: 4px;
  padding: 3px;
  margin: 1px;
  border-color: #ddd;
  background: transparent;
}

.ais-Pagination-item .ais-Pagination-link {
  display: block;
  color: #FF0000;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.ais-Pagination-item.ais-Pagination-item--selected.ais-Pagination-item--page {
  background: #FF0000;
}

.ais-Pagination-item.ais-Pagination-item--selected.ais-Pagination-item--page
  .ais-Pagination-link {
  color: #ffffff;
  border-color: #FF0000;
}

.ais-Pagination-item--disabled {
  visibility: hidden;
}

.thank-you {
  text-align: center;
  font-size: 0.8em;
}

.thank-you a {
  color: #FF0000;
}

/* RHEOSTAT RANGE
// ------------------------- */
.rheostat-container {
  display: flex;
  align-items: center;
}
.rheostat {
  height: 24px;
  position: relative;
  overflow: visible;
}

.rheostat-background {
  background: #dce0e0;
  height: 2px;
  position: relative;
  top: 14px;
  width: 100%;
}

.rheostat-values {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
}

.rheostat--disabled .rheostat-progress {
  background-color: #edefed;
}

.rheostat--disabled .rheostat-handle {
  cursor: default;
}

.rheostat-progress {
  background-color: #FF0000;
  height: 4px;
  position: absolute;
  top: 13px;
}

.rheostat-handle {
  border: 1px solid #FF0000;
  background: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 24px;
  margin-left: -12px;
  position: absolute;
  z-index: 2;
  width: 24px;
  font-size: 0;
}
